<template>
  <div class="discussion-content">
    <div class="discussion-wrap">
      <div class="__header">
        <h4>Discussion board</h4>
        <div class="dropdown">
          <button
            v-if="schedules.length != 0"
            class="btn cta-small dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Create Discussion
          </button>
        </div>
      </div>
      <div class="__body" v-if="isLoading == false">
        <ul class="list-unstyled" v-if="discussions.length > 0">
          <li
            v-for="(discussion, discussionIndex) in discussions"
            :key="discussionIndex"
          >
            <router-link
              :to="{
                name: 'nestDiscussionReply',
                params: { id: discussion.id }
              }"
              class="discussion-card"
            >
              <div class="stamp">{{ discussion.created_date }}</div>
              <div class="thumbnail">
                <img :src="discussion.image" alt="user image" />
              </div>
              <div class="_card-body row">
                <div class="discuss-body col-md-8">
                  <div class="header">
                    <h5>
                      {{ discussion.first_name }} {{ discussion.last_name }}
                    </h5>
                    <span class="physics">{{ discussion.title }}</span>
                  </div>
                  <p class="status">
                    {{ discussion.created_date }} - {{ discussion.time }}
                  </p>
                  <p class="body">
                    {{ discussion.discussion }}
                  </p>
                </div>
                <div class="_action-bar col-md-4">
                  <ul class="list-unstyled thumblist">
                    <li
                      v-for="(child, childIndex) in discussion.children.slice(
                        0,
                        3
                      )"
                      :key="childIndex"
                    >
                      <img :src="child.image" alt="" />
                    </li>
                    <li v-if="discussion.children.length > 3">
                      <a href="#">+{{ discussion.children.length - 3 }}</a>
                    </li>
                  </ul>
                  <p>{{ discussion.children.length }} comments</p>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="empty" v-else-if="schedules.length == 0">
          <p>Please subscribe to start discussion</p>
        </div>
        <div class="empty" v-else>
          <p>No discussion found</p>
        </div>
      </div>
      <webinar-loader v-else></webinar-loader>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Start Discussion</h5>
            <button
              type="button"
              class="btn-close"
              ref="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveDiscussion">
            <div class="modal-body">
              <div class="form-group col-md-12">
                <div class="mb-3 mt-3">
                  <label for="schedule" class="form-lab el">Schedule</label>
                  <select class="form-control" v-model="schedule" id="schedule">
                    <option value="">Select schedule</option>
                    <option
                      v-for="(schedule, scheduleIndex) in schedules"
                      :key="scheduleIndex"
                      :value="schedule.id"
                    >
                      {{ schedule.title }}
                    </option>
                  </select>
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.schedule.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <div class="mb-3">
                  <label class="form-label">Discussion</label>
                  <textarea
                    class="form-control"
                    v-model="discussion"
                  ></textarea>
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.discussion.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.discussion" class="error-msg">
                    {{ errors.discussion[0] }}
                  </div>
                </div>
              </div>
              <!-- <div class="paging-wrapper">
                <v-pagination
                  v-model="completedSchedulePage"
                  :pages="completedScheduleTotalPages"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="completedScheduleChangeHandler"
                  v-if="completedScheduleTotalClass > 0"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <input type="submit" class="btn cta-primary" value="Save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NestScheduleService from "@/services/NestScheduleService";
import NestDiscussionService from "@/services/NestDiscussionService";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import errorLog from "@/errorLog";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },

  name: "nestDiscussions",
  components: {
    WebinarLoader
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  created() {
    this.getScheduleList();
    this.getDiscussion();
  },
  data() {
    return {
      discussions: [],
      isLoading: true,
      schedule: "",
      discussion: "",
      schedules: [],
      errors: {}
    };
  },
  validations() {
    return {
      discussion: {
        required: helpers.withMessage("Please enter comment", required),
        maxLength: maxLength(2400)
      },
      schedule: {
        required: helpers.withMessage("Please select schedule", required)
      }
    };
  },
  methods: {
    getScheduleList() {
      NestScheduleService.getScheduleList()
        .then(result => {
          if (result.data.schedules != null) {
            this.schedules = result.data.schedules;
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getDiscussion() {
      NestDiscussionService.getParentDiscussions()
        .then(result => {
          this.discussions = result.data;
          this.isLoading = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    saveDiscussion: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const data = {
        discussion: this.discussion,
        special_event_id: this.schedule
      };
      NestDiscussionService.saveDiscussion(data)
        .then(result => {
          if (result.data.status == "error") {
            this.$toast.error(result.data.message);
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          } else {
            this.$toast.success(result.data.message);
            this.getDiscussion();
            this.$refs.closeModal.click();
            this.discussion = "";
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
