import Http from "@/services/Http";
export default {
  getScheduleList(data) {
    return Http().post("nest/schedule/list", data);
  },
  getMeetingDetails(data) {
    return Http().post("nest/schedule/details", data);
  },
  completeEvent(data) {
    return Http().post("nest/schedule/end", data);
  },
  getRecording(data) {
    return Http().post("nest/recording", data);
  }
};
